import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { LOCAL_STORAGE } from '../utils/constants';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { User } from '../../interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private readonly token: string | null = sessionStorage.getItem(LOCAL_STORAGE.accessToken);
  private readonly headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${this.token}`,
  });

  private readonly userInfoEndpoint = `${environment.apiBaseUrl}/api/users/profile`;
  private readonly usersEndpoint = `${environment.apiBaseUrl}/api/users`;

  constructor(
    private readonly auth: AuthenticationService,
    private readonly http: HttpClient,
    private readonly router: Router
  ) {}

  private logError(...errors: any[]) {
    if (!environment.production) {
      console.error(...errors);
    }
  }

  // Fetch logged-in user's profile
  async getUserInfo(): Promise<User | null> {
    try {
      const result: { success: boolean; data: User; message?: string } = await firstValueFrom(
        this.http.get<{ success: boolean; data: User; message?: string }>(this.userInfoEndpoint, { headers: this.headers })
      );
      if (result.success) {
        return result.data;
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      this.logError('Error fetching user profile:', error);
      throw error;
    }
  }

  // Fetch a specific user's details by ID (admin access)
  async getUserById(userId: string): Promise<User | null> {
    try {
      const result: { success: boolean; data: User; message?: string } = await firstValueFrom(
        this.http.get<{ success: boolean; data: User; message?: string }>(`${this.usersEndpoint}/${userId}`, { headers: this.headers })
      );
      if (result.success) {
        return result.data;
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      this.logError('Error fetching user details:', error);
      throw error;
    }
  }

  // Fetch all users (admin access)
  async getAllUsers(): Promise<User[] | null> {
    try {
      const result: { success: boolean; data: User[]; message?: string } = await firstValueFrom(
        this.http.get<{ success: boolean; data: User[]; message?: string }>(this.usersEndpoint, { headers: this.headers })
      );
      if (result.success) {
        return result.data;
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      this.logError('Error fetching users:', error);
      throw error;
    }
  }

  // Update user profile
  async updateUserProfile(userData: Partial<User>): Promise<User | null> {
    try {
      const result: { success: boolean; data: User; message?: string } = await firstValueFrom(
        this.http.put<{ success: boolean; data: User; message?: string }>(this.userInfoEndpoint, userData, { headers: this.headers })
      );
      if (result.success) {
        return result.data;
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      this.logError('Error updating user profile:', error);
      throw error;
    }
  }

  // Delete a user (admin access)
  async deleteUser(userId: string): Promise<boolean> {
    try {
      const result: { success: boolean; message?: string } = await firstValueFrom(
        this.http.delete<{ success: boolean; message?: string }>(`${this.usersEndpoint}/${userId}`, { headers: this.headers })
      );
      if (result.success) {
        return true;
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      this.logError('Error deleting user:', error);
      throw error;
    }
  }

  // Check if the user is an admin
  async isAdmin(): Promise<boolean> {
    const token = sessionStorage.getItem(LOCAL_STORAGE.accessToken);
    if (!token) {
      return false;
    }

    try {
      const decoded: { id: string } = jwtDecode(token);
      const user = await this.getUserById(decoded.id);
      return user ? user.isAdmin : false;
    } catch (error) {
      this.logError('Error checking admin status:', error);
      throw error;
    }
  }
}