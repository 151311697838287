import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileService } from '../services/profile.service';
import { User } from '../../interfaces/user';
import { Router } from '@angular/router';
import bcrypt from 'bcryptjs';

@Component({
  selector: 'app-updateuserprofile',
  templateUrl: './updateuserprofile.component.html',
  styleUrls: ['./updateuserprofile.component.scss']
})
export class UpdateuserprofileComponent implements OnInit {
  profileForm: FormGroup;
  user: User | null = null;
  loading = false;
  errorMessage = '';
  showOldPassword = false;
  showNewPassword = false;
  showConfirmNewPassword = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly profileService: ProfileService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar
  ) {
    this.profileForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      username: ['', Validators.required],
      phoneNumber: [''],
      currency: [''],
      profileType: ['adult', Validators.required],
      oldPassword: [''],
      newPassword: [''],
      confirmNewPassword: ['']
    }, { validators: [this.passwordMatchValidator, this.passwordFieldsValidator] });
  }

  ngOnInit(): void {
    this.loadUserProfile();
  }

  async loadUserProfile() {
    this.loading = true;
    try {
      this.user = await this.profileService.getUserInfo();
      if (this.user) {
        this.profileForm.patchValue(this.user);
      }
    } catch (error) {
      this.showErrorMessage('Failed to load user profile');
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  async updateProfile() {
    if (this.profileForm.invalid) {
      return;
    }

    this.loading = true;
    try {
      const formData = this.profileForm.value;
      const userData: any = {
        email: formData.email,
        username: formData.username,
        phoneNumber: formData.phoneNumber,
        currency: formData.currency,
        profileType: formData.profileType
      };

      if (formData.oldPassword) {
        const isOldPasswordValid = await bcrypt.compare(formData.oldPassword, this.user?.password ?? '');
        if (!isOldPasswordValid) {
          this.showErrorMessage('Old password is incorrect');
          this.loading = false;
          return;
        }
        userData.oldPassword = formData.oldPassword;
        userData.newPassword = formData.newPassword;
      }

      const updatedUser = await this.profileService.updateUserProfile(userData);
      if (updatedUser) {
        this.user = updatedUser;
        this.showSuccessMessage('Profile updated successfully');
        this.router.navigate(['/content']); // Redirect to content page
      } else {
        this.showErrorMessage('Failed to update profile');
      }
    } catch (error) {
      this.handleErrorResponse(error);
    } finally {
      this.loading = false;
    }
  }

  handleErrorResponse(error: any) {
    const errorMessage = error.error?.message || 'Oops! Something went wrong.';
    const statusCode = error.error?.statusCode || error.status;

    switch (statusCode) {
      case 400:
        this.showErrorMessage('Bad Request');
        break;
      case 401:
        this.showErrorMessage('Unauthorized');
        break;
      case 403:
        this.showErrorMessage('Forbidden');
        break;
      case 404:
        this.showErrorMessage('Not Found');
        break;
      case 500:
        this.showErrorMessage('Internal Server Error');
        break;
      default:
        this.showErrorMessage(errorMessage);
        break;
    }
  }

  goBack() {
    this.router.navigate(['/profile']);
  }

  passwordMatchValidator(form: AbstractControl): ValidationErrors | null {
    const newPassword = form.get('newPassword')?.value;
    const confirmNewPassword = form.get('confirmNewPassword')?.value;
    if (newPassword && newPassword !== confirmNewPassword) {
      return { passwordMismatch: true };
    }
    return null;
  }

  passwordFieldsValidator(form: AbstractControl): ValidationErrors | null {
    const oldPassword = form.get('oldPassword')?.value;
    const newPassword = form.get('newPassword')?.value;
    const confirmNewPassword = form.get('confirmNewPassword')?.value;

    if (oldPassword && (!newPassword || !confirmNewPassword)) {
      return { passwordFieldsIncomplete: true };
    }

    if (newPassword && newPassword.length < 6) {
      return { newPasswordTooShort: true };
    }

    return null;
  }

  togglePasswordVisibility(field: string) {
    if (field === 'old') {
      this.showOldPassword = !this.showOldPassword;
    } else if (field === 'new') {
      this.showNewPassword = !this.showNewPassword;
    } else if (field === 'confirm') {
      this.showConfirmNewPassword = !this.showConfirmNewPassword;
    }
  }

  showErrorMessage(message: string) {
    this.snackBar.open(`❌ ${message}`, 'Close', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['snackbar--error']
    });
  }

  showSuccessMessage(message: string) {
    this.snackBar.open(`✅ ${message}`, 'Close', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['snackbar--success']
    });
  }
}