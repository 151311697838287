import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-light-logo',
  templateUrl: './light-logo.component.html',
  styleUrls: ['./light-logo.component.scss']
})
export class LightLogoComponent {
  constructor(private readonly router: Router) {}

  logoClicked() {
    this.router.navigate(["/content"]);
  }

  onKeyPress(event: KeyboardEvent) {
    // handle key press event
    console.log('Key Pressed:', event.key);
  }

  onKeyDown(event: KeyboardEvent) {
    // handle key down event
    console.log('Key Down:', event.key);
  }

  onKeyUp(event: KeyboardEvent) {
    // handle key up event
    console.log('Key Up:', event.key);
  }
}