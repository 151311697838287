export const environment = {
    // production: true,
    production: false,
    apiBaseUrl: 'https://niftytvworkplusbackend-419842304103.us-central1.run.app',
    // apiBaseUrl: 'http://localhost:5000',
    firebaseConfig: {
        // Firebase configuration for development environment
        apiKey: "...",
        authDomain: "...",
        projectId: "...",
        storageBucket: "...",
        messagingSenderId: "...",
        appId: "..."
    }
};
