<section class="welcome-message">
  <div class="left-section"></div>
  <div class="center-section">
    <h1>Reclaiming African Hearts, One Frame at a Time</h1>
    <p>Discover a world of premium locally-produced family-friendly African entertainment.</p>
    <a routerLink="/signup" class="centered-btn">
      <button mat-stroked-button class="start-free-trial-btn">{{ startFreeTrialBtnLabel }}</button>
    </a>
  </div>
  <div class="right-section"></div>
</section>