<div class="carousel-container">
  <app-header></app-header>

  <ng-container *ngIf="contentData?.data; else noContent">
    <div class="image-container">
      <img class="img-fluid" [src]="contentData.data.image" alt="{{ contentData.data.title }}" />

      <div class="overlay">
        <h1>{{ contentData.data.title }}</h1>

        <div class="metadata">
          <span *ngIf="contentData.data.movieMetadata?.director || contentData.data.showMetadata?.director || contentData.data.podcastMetadata?.author">
            {{ contentData.data.movieMetadata?.director || contentData.data.showMetadata?.director || contentData.data.podcastMetadata?.author }}
          </span>
          <span *ngIf="contentData.data.movieMetadata?.releaseDate || contentData.data.showMetadata?.releaseDate || contentData.data.podcastMetadata?.releaseDate">
            {{ (contentData.data.movieMetadata?.releaseDate || contentData.data.showMetadata?.releaseDate || contentData.data.podcastMetadata?.releaseDate) | date:'fullDate' }}
          </span>
          <span *ngIf="contentData.data.movieMetadata?.duration || contentData.data.showMetadata?.duration || contentData.data.podcastMetadata?.duration">
            {{ (contentData.data.movieMetadata?.duration || contentData.data.showMetadata?.duration || contentData.data.podcastMetadata?.duration) | duration }}
          </span>
          <span *ngIf="contentData.data.movieMetadata?.genre || contentData.data.showMetadata?.genre || contentData.data.podcastMetadata?.genre">
            {{ contentData.data.movieMetadata?.genre || contentData.data.showMetadata?.genre || contentData.data.podcastMetadata?.genre }}
          </span>
        </div>
      </div>
    </div>

    <div class="button-group">
      <app-play-button [contentData]="contentData" (play)="onPlay($event)"></app-play-button>
    </div>
  </ng-container>

  <ng-template #noContent>
    <div class="image-container no-content">
      <div class="overlay">
        <h1>Welcome Onboard!</h1>
        <div class="desc">Stay tuned for exciting content coming your way!</div>

        <div class="metadata">
          <span>Director: N/A</span>
          <span>Release Date: N/A</span>
          <span>Duration: N/A</span>
          <span>Genre: N/A</span>
        </div>
      </div>

      <div class="button-group">
        <!-- You might want to disable this button or show a message for no content -->
        <app-play-button [disabled]="true"></app-play-button>
      </div>
    </div>
  </ng-template>
</div>