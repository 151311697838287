import { Component, Renderer2, ElementRef, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ContentService } from '../../services/content.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ThemesService } from '../../services/themes.service';
import { LOCAL_STORAGE } from 'src/app/utils/constants';
import { SearchService } from 'src/app/services/search.service';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { clearStore } from 'src/app/store/actions/auth.actions';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionModalComponent } from 'src/app/components/subscription-modal/subscription-modal.component';
import { PaymentPlanService } from 'src/app/services/payment-plan.service';
import { Filter, AllContentResponse } from 'src/interfaces/content';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  selectedTheme!: string;
  content: AllContentResponse = {
    movies: { status: '', code: 0, data: [], message: '' },
    podcasts: { status: '', code: 0, data: [], message: '' },
    shows: { status: '', code: 0, data: [], message: '' },
    rssFeeds: { status: '', code: 0, data: [], message: '' },
  };
  excludeRoute: boolean = false;
  hasActiveSubscription: boolean = false;
  subscriptionId: string | null = null;
  query: string = '';
  contentType: string = 'Movies';
  selectedFilter: string = 'Title';
  filterValue: string = '';
  isCollapsed: boolean = true;

  @Output() searchQueryChange = new EventEmitter<string>();

  constructor(
    public router: Router,
    private readonly contentService: ContentService,
    private readonly subscriptionService: SubscriptionService,
    private readonly themeService: ThemesService,
    private readonly route: ActivatedRoute,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly searchService: SearchService,
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly paymentPlanService: PaymentPlanService,
    private readonly snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    const excludeRoutes = ['', 'signin', 'signup', 'splash'];

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.excludeRoute = excludeRoutes.includes(event.urlAfterRedirects.split('/')[1]);
      }
    });

    this.checkSubscriptionStatus();
    this.loadOriginalContent();
  }

  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
  }

  private checkSubscriptionStatus() {
    const token = sessionStorage.getItem(LOCAL_STORAGE.accessToken);

    if (!token) {
      this.hasActiveSubscription = false;
      return;
    }

    interface ExtendedJwtPayload extends JwtPayload {
      id: string;
    }

    const decodedToken = jwtDecode(token) as ExtendedJwtPayload;
    const userId = decodedToken.id;

    this.subscriptionService.hasActiveSubscription(userId).subscribe({
      next: (hasActiveSubscription) => {
        this.hasActiveSubscription = hasActiveSubscription;
      },
      error: (error) => {
        console.error('Error checking subscription status:', error);
        this.hasActiveSubscription = false;
      }
    });
  }

  private loadOriginalContent() {
    this.contentService.getAllContent().subscribe({
      next: (content: AllContentResponse) => {
        this.content = content;
        this.searchService.setSearchResults(content);
      },
      error: (error) => {
        this.openSnackBar(error);
      }
    });
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['snackbar--error']
    });
  }

  onThemeSelected(selectedTheme: string) {
    if (this.hasActiveSubscription) {
      this.themeService.setSelectedTheme(selectedTheme || '');
      this.router.navigate(['/themes', selectedTheme.toLowerCase()]);
    } else {
      this.paymentPlanService.getAllPlans().subscribe({
        next: (plans) => {
          this.dialog.open(SubscriptionModalComponent, { data: { plans } });
        },
        error: (error) => {
          console.error('Error fetching subscription plans:', error);
          this.dialog.open(SubscriptionModalComponent, { data: { plans: [] } });
        }
      });
    }
  }

  onSearchQueryChange(event: any) {
    this.searchQueryChange.emit(this.query);
  }

  setFilter(filter: string) {
    this.selectedFilter = filter;
    this.filterValue = '';
  }

  setContentType(type: string) {
    this.contentType = this.capitalizeFirstLetter(type);
    this.search();
  }

  search() {
    const filter: Partial<Filter> = {
      [this.selectedFilter.toLowerCase().replace(' ', '')]: this.filterValue
    };

    if (this.filterValue) {
      this.contentService.filter(this.contentType.toLowerCase(), filter).subscribe((res) => {
        this.searchService.setSearchResults(res);
        this.searchService.emitQueryChange(this.contentType.toLowerCase(), filter);
        this.searchQueryChange.emit(this.query);
      });
    } else {
      console.warn('Filter value must be provided.');
      this.searchService.setSearchResults({});
      this.searchQueryChange.emit('');
    }
  }

  onsearchQuery(event: any) {
    this.search();
  }

  clearSearch() {
    this.filterValue = '';
    this.selectedFilter = 'Title';
    this.contentType = 'Movies';
    this.loadOriginalContent();
    this.searchService.emitQueryChange(this.contentType.toLowerCase(), {});
    this.searchQueryChange.emit('');
  }

  onProfileClick() {
    this.router.navigate(['/profile']);
  }

  signOut() {
    sessionStorage.clear();
    this.store.dispatch(clearStore());
    this.router.navigate(['/signin']);
  }

  viewSubscriptionDetails() {
    if (this.subscriptionId) {
      this.router.navigate(['/subscription-details', this.subscriptionId]);
    } else {
      console.error('Subscription ID is not available.');
    }
  }

  public capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
}