import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationBarComponent implements OnInit {
  signUpBtnLabel: string = 'Sign up';
  signInBtnLabel: string = 'Login';
  menuActive: boolean = false;
  isFaqPage: boolean = false;

  constructor(private readonly router: Router, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.menuActive = false;
        this.isFaqPage = event.urlAfterRedirects.includes('/faq');
        this.cdr.markForCheck();
      }
    });
  }

  toggleMenu() {
    this.menuActive = !this.menuActive;
  }
}