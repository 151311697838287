import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  contactForm: FormGroup;
  submissionSuccess = false;
  submissionError = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly contactService: ContactService,
    private readonly snackBar: MatSnackBar
  ) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      this.contactService.submitContactForm(this.contactForm.value).subscribe({
        next: () => {
          this.submissionSuccess = true;
          this.submissionError = false;
          this.contactForm.reset();
          this.showSuccessMessage('Your message has been sent successfully!');
        },
        error: () => {
          this.submissionSuccess = false;
          this.submissionError = true;
          this.showErrorMessage('There was an error sending your message. Please try again later.');
        }
      });
    }
  }

  goBack(): void {
    this.router.navigate(['/']);
  }

  private showErrorMessage(message: string): void {
    this.snackBar.open(`❌ ${message}`, 'Close', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['snackbar--error']
    });
  }

  private showSuccessMessage(message: string): void {
    this.snackBar.open(`✅ ${message}`, 'Close', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['snackbar--success']
    });
  }
}