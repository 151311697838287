<footer class="footer">
  <div class="footer-content">
    <div class="logo">
      <img src="../../assets/logo-light.png" alt="Logo">
      <div class="links">
        <a href="/about">About Us</a>
        <a href="/contact">Contact Us</a>
        <a href="/faq">FAQ</a>
      </div>
    </div>
    <div class="social-media">
      <a href="https://web.facebook.com/niftyworksplus/?_rdc=1&_rdr#" target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
      <a href="https://x.com/niftyworks_plus" target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
      <a href="https://instagram.com/nwplus.tv" target="_blank"><i class="fa-brands fa-instagram"></i></a>
      <a href="https://www.youtube.com/channel/UCwxkaXirPZnVLK0B7s60Bhw/videos" target="_blank"><i class="fa-brands fa-youtube"></i></a>
    </div>
  </div>
  <div class="footer-bottom">
    <p>&copy; {{ currentYear }} NW+ TV. All rights reserved.</p>
  </div>
</footer>