import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent {
  otpForm: FormGroup;
  loading: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar
  ) {
    this.otpForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  onSubmit() {
    if (this.otpForm.valid) {
      this.loading = true;
      const email = this.otpForm.get('email')?.value;
      const otp = this.otpForm.get('otp')?.value;
      this.authService.verifyOTP(email, otp).subscribe({
        next: (response) => {
          this.loading = false;
          if (response.success) {
            this.showSuccessMessage('Account verified successfully. Please sign in.');
            this.router.navigate(['/signin']);
          } else {
            this.showErrorMessage('OTP verification failed. Please try again.');
          }
        },
        error: (err) => {
          console.error('Error:', err);
          this.loading = false;
          if (err.error ?? err.error.error) {
            this.handleErrorResponse(err.error.error);
          } else {
            this.showErrorMessage('An unexpected error occurred. Please try again later.');
          }
        }
      });
    }
  }

  handleErrorResponse(error: string) {
    switch (error) {
      case 'User not found':
        this.showErrorMessage('No user found with this email.');
        break;
      case 'Invalid OTP':
        this.showErrorMessage('The OTP you entered is incorrect.');
        break;
      case 'OTP has expired':
        this.showErrorMessage('The OTP has expired. Please request a new one.');
        break;
      default:
        this.showErrorMessage('An unexpected error occurred. Please try again later.');
    }
  }

  goBack() {
    this.router.navigate(['/verify-email']);
  }

  showErrorMessage(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['snackbar--error']
    });
  }

  showSuccessMessage(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['snackbar--success']
    });
  }
}