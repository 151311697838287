<div class="faq-container">
  <app-navigation-bar></app-navigation-bar>
  
  <div class="faq-content">
    <div class="faq-header">
      <div class="back-button" (click)="goBack()">
        <span class="back-icon">
          <i class="fas fa-arrow-left"></i>
        </span>
        <span class="back-text">Back</span>
      </div>
      <h1>Frequently Asked Questions</h1>
    </div>
    
    <!-- Search Bar -->
    <div class="search-bar">
      <input type="text" placeholder="Search FAQs..." (input)="filterFaqs($event)">
    </div>
    
    <!-- FAQ Categories -->
    <div class="faq-categories">
      <button *ngFor="let category of categories" (click)="filterByCategory(category)">
        {{ category }}
      </button>
    </div>
    
    <!-- FAQ Items -->
    <div *ngFor="let faq of filteredFaqs" class="faq-item">
      <h3 (click)="toggleAnswer(faq)">
        {{ faq.question }}
        <span class="icon" [ngClass]="{'expanded': faq.showAnswer}">
          <i class="fas fa-chevron-down"></i>
        </span>
      </h3>
      <div [ngClass]="{'show': faq.showAnswer}" class="faq-answer">
        <div [innerHTML]="faq.answer | safeHtml"></div>
      </div>
    </div>
  </div>
  
  <app-footer></app-footer>
  
  <!-- Scroll to Top Button -->
  <button class="scroll-to-top" [ngClass]="{'show': showScrollToTop}" (click)="scrollToTop()">
    <i class="fas fa-arrow-up"></i>
  </button>
</div>