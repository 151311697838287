<header class="header" aria-label="Main Navigation">
  <app-light-logo></app-light-logo>
  <button class="hamburger" aria-label="Toggle Navigation" (click)="toggleMenu()">
    <span class="hamburger-line"></span>
    <span class="hamburger-line"></span>
    <span class="hamburger-line"></span>
  </button>
  <nav class="menu" [class.active]="menuActive" aria-label="Primary Navigation">
    <a href="https://www.nwplus.biz/about-us/">About Us</a>
    <a routerLink="/contact">Contact Us</a>
    <a routerLink="/faq" *ngIf="!isFaqPage">FAQ</a>
  </nav>
  <nav class="auth-buttons" [class.active]="menuActive" aria-label="Authentication Buttons">
    <a routerLink="/signin">
      <app-inactive-btn class="btn--sign-in" [buttonAction]="signInBtnLabel" buttonClass="sign-in-btn"></app-inactive-btn>
    </a>
    <a routerLink="/signup">
      <app-active-btn class="btn--sign-up highlighted" [buttonAction]="signUpBtnLabel"></app-active-btn>
    </a>
  </nav>
  <nav class="mobile-menu" [class.active]="menuActive" aria-label="Mobile Navigation">
    <a href="https://www.nwplus.biz/about-us/">About Us</a>
    <a routerLink="/contact">Contact Us</a>
    <a routerLink="/faq" *ngIf="!isFaqPage">FAQ</a>
    <a routerLink="/signin">Sign In</a>
    <a routerLink="/signup">Sign Up</a>
  </nav>
</header>