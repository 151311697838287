<section class="splash-section">
  <div class="upper-section">
    <div class="image-container">
      <img src="../../assets/HomeTwo.png" alt="HomeTwo">
      <div class="overlay"></div>
    </div>
    <div class="image-container">
      <img src="../../assets/HomeOne.png" alt="HomeOne">
      <div class="overlay"></div>
    </div>
    <div class="image-container">
      <img src="../../assets/HomeThree.png" alt="HomeThree">
      <div class="overlay"></div>
    </div>
    <div class="image-container">
      <img src="../../assets/HomeFour.png" alt="HomeFour">
      <div class="overlay"></div>
    </div>
  </div>
  <div class="lower-section">
    <div class="subsection upper">
      <div class="small-rectangle pure-entertainment">
        <h2>Pure Entertainment</h2>
      </div>
      <div class="big-rectangle watch-anytime">
        <h2>Watch Anytime, Anywhere</h2>
        <p>Stream your favorite content on any device—mobile, tablet, or Desktop</p>
      </div>
    </div>
    <div class="subsection lower">
      <div class="big-rectangle highlighting-excellence">
        <h2>Highlighting African Excellence</h2>
        <p>A platform dedicated to showcasing the best of African storytelling, talent, and creativity.</p>
      </div>
      <div class="small-rectangle trial">
        <h2><span class="big-text">24h</span> Free Trial</h2>
      </div>
    </div>
  </div>
  <div class="additional-section">
    <div class="subsection text-logo">
      <div class="logo">
        <img src="../../assets/logo-light.png" alt="Logo">
      </div>
      <div class="text-content">
        <div>
          <p>Join NW+ TV to experience the essence of Africa, on-the-go.</p>
        </div>
        <a href="/signup" class="start-button">Start For Free</a>
      </div>
    </div>
    <div class="subsection image">
      <img src="../../assets/HomeFive.png" alt="HomeFive">
      <div class="additional-overlay"></div>
    </div>
  </div>
</section>