import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { LOCAL_STORAGE } from '../utils/constants';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {
  email: string | null = window.sessionStorage.getItem(LOCAL_STORAGE.emailForSignIn);

  constructor(
    private readonly auth: AuthenticationService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    if (!this.email) {
      this.router.navigate(['/signin']).catch(err => {
        this.showErrorMessage('Navigation error: ' + err.message);
      });
    }
  }

  resendLink(): void {
    if (this.email) {
      this.auth.resendOTP(this.email).subscribe({
        next: () => {
          this.showSuccessMessage('OTP has been resent successfully.');
        },
        error: (err) => {
          this.showErrorMessage('Failed to resend OTP: ' + err.message);
        }
      });
    } else {
      this.showErrorMessage('Email is not available for resending OTP');
    }
  }

  onOkayButtonClick(): void {
    this.router.navigate(['/verify-otp']).catch(err => {
      this.showErrorMessage('Navigation error: ' + err.message);
    });
  }

  showErrorMessage(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['snackbar--error']
    });
  }

  showSuccessMessage(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['snackbar--success']
    });
  }

  onKeyPress(event: KeyboardEvent): void {
    console.log('Key Pressed:', event.key);
  }

  onKeyDown(event: KeyboardEvent): void {
    console.log('Key Down:', event.key);
  }

  onKeyUp(event: KeyboardEvent): void {
    console.log('Key Up:', event.key);
  }
}