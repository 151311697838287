import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageStateService {
  private readonly isFaqPageSubject = new BehaviorSubject<boolean>(false);
  isFaqPage$ = this.isFaqPageSubject.asObservable();

  setIsFaqPage(isFaqPage: boolean): void {
    this.isFaqPageSubject.next(isFaqPage);
  }
}