import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { catchError, tap, map } from 'rxjs/operators';
import { jwtDecode } from "jwt-decode";
import { environment } from "src/environments/environment";
import { LOCAL_STORAGE } from "../utils/constants";
import { windowRef } from "../utils/windowRef";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router
  ) { }

  // Handle user management action
  handleUserManagementAction(mode: string, actionCode: string): void {
    if (!actionCode) {
      console.error('Action code is null or undefined');
      return;
    }

    switch (mode) {
      case 'resetPassword':
        this.router.navigate(['/update-password']);
        break;
      case 'verifyEmail':
        this.verifyEmail(actionCode).subscribe({
          next: () => {
            this.router.navigate(['/verify-email']);
          },
          error: (err) => console.error('Email verification failed', err)
        });
        break;
      case 'verifyOTP':
        this.verifyOTP(actionCode, '').subscribe({
          next: () => {
            this.router.navigate(['/verify-otp']);
          },
          error: (err) => console.error('OTP verification failed', err)
        });
        break;
      default:
        console.error('Unknown mode:', mode);
        break;
    }
  }
  
  // Sign up
  signUp(email: string, username: string, phoneNumber: string, password: string, termsAccepted: boolean, termsAcceptedAt: Date): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/signup`;
    const body = { email, username, phoneNumber, password, termsAccepted, termsAcceptedAt };
    return this.http.post<any>(url, body).pipe(
      catchError(this.handleError('signUp'))
    );
  }

  // Sign up admin
  signUpAdmin(email: string, username: string, phoneNumber: string, password: string, adminSecret: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/signup/admin`;
    const body = { email, username, phoneNumber, password, adminSecret };
    return this.http.post<any>(url, body).pipe(
      catchError(this.handleError('signUpAdmin'))
    );
  }

  // Sign in
  signIn(email: string, password: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/signin`;
    const body = { email, password };
    return this.http.post<any>(url, body).pipe(
      tap((response: any) => {
        if (response.success && response.data.accessToken) {
          sessionStorage.setItem(LOCAL_STORAGE.accessToken, response.data.accessToken);
        }
      }),
      catchError(this.handleError('signIn'))
    );
  }

  // Google Sign-In
  googleSignIn(context: string): void {
    // Redirect to the backend endpoint that handles Google OAuth sign-in
    windowRef().location.href = `${environment.apiBaseUrl}/api/auth/google?context=${context}&prompt=select_account`;
  }

  // Google Sign-In callback handler
  handleGoogleCallback(): Observable<boolean> {
    const code = this.getCodeFromUrl();
    if (code) {
      return this.http.post(`${environment.apiBaseUrl}/api/auth/google/callback`, { code }).pipe(
        map((response: any) => {
          if (response.token) {
            sessionStorage.setItem(LOCAL_STORAGE.accessToken, response.token);
            this.router.navigate(['/content']); // Redirect to your desired page
            return true;
          } else {
            this.router.navigate(['/signin'], { queryParams: { error: 'auth_failed' } });
            return false;
          }
        }),
        catchError(error => {
          this.router.navigate(['/signin'], { queryParams: { error: 'auth_failed' } });
          return of(false);
        })
      );
    } else {
      this.router.navigate(['/signin'], { queryParams: { error: 'auth_failed' } });
      return of(false);
    }
  }

  // Utility to extract code from URL
  private getCodeFromUrl(): string | null {
    const urlParams = new URLSearchParams(windowRef().location.search);
    return urlParams.get('code');
  }

  // Verify email
  verifyEmail(token: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/verify/${token}`;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError('verifyEmail'))
    );
  }

  // Send email verification
  sendEmailVerification(): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/send-email-verification`;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError('sendEmailVerification'))
    );
  }

  // Send password reset email
  sendPasswordResetEmail(email: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/send-password-reset-email`;
    const body = { email };
    return this.http.post<any>(url, body).pipe(
      catchError(this.handleError('sendPasswordResetEmail'))
    );
  }

  // Reset password with email
  resetPasswordWithEmail(token: string, password: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/reset-password`;
    const body = { token, password };
    return this.http.post<any>(url, body).pipe(
      catchError(this.handleError('resetPasswordWithEmail'))
    );
  }

  // Verify OTP
  verifyOTP(email: string, otp: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/verify-otp`;
    const body = { email, otp };
    return this.http.post<any>(url, body).pipe(
      catchError(this.handleError('verifyOTP'))
    );
  }

  // Resend OTP
  resendOTP(email: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/resend-otp`;
    const body = { email };
    return this.http.post<any>(url, body).pipe(
      catchError(this.handleError('resendOTP'))
    );
  }

  // Check if user is authenticated
  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      const token = sessionStorage.getItem(LOCAL_STORAGE.accessToken);
      if (!token) {
        resolve(false);
      } else {
        try {
          const decoded: any = jwtDecode(token);
          const currentTime = Math.floor(Date.now() / 1000);
          if (decoded.exp && decoded.exp >= currentTime) {
            resolve(true);
          } else {
            this.signOut();
            resolve(false);
          }
        } catch (error) {
          this.signOut();
          resolve(false);
        }
      }
    });
  }

  // Sign out
  signOut(): void {
    sessionStorage.removeItem(LOCAL_STORAGE.accessToken);
    this.router.navigate(["/signin"]);
  }

  // Forgot password
  forgotPassword(email: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/forgot-password`;
    const body = { email };
    return this.http.post<any>(url, body).pipe(
      catchError(this.handleError('forgotPassword'))
    );
  }

  // Reset password
  resetPassword(email: string, otp: string, newPassword: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/reset-password`;
    const body = { email, otp, newPassword };
    return this.http.post<any>(url, body).pipe(
      catchError(this.handleError('resetPassword'))
    );
  }

  // Change password
  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/change-password`;
    const body = { oldPassword, newPassword };
    return this.http.post<any>(url, body).pipe(
      catchError(this.handleError('changePassword'))
    );
  }

  // Delete user account
  deleteUserAccount(confirmation: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/account`;
    const token = sessionStorage.getItem(LOCAL_STORAGE.accessToken);
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = { confirmation };
    return this.http.request<any>('delete', url, { headers, body }).pipe(
      tap(() => this.signOut()),
      catchError(this.handleError('deleteUserAccount'))
    );
  }

  // Handle HTTP errors
  private handleError(operation = 'operation') {
    return (error: any): Observable<any> => {
      console.error(`${operation} failed: ${error.message}`); // Log the error
      return throwError(error); // Return an observable with a user-facing error message
    };
  }
}