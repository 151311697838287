import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  OnChanges,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  SimpleChanges,
  ViewEncapsulation,
  Inject,
  PLATFORM_ID
} from '@angular/core';
import { Router } from '@angular/router';
import Plyr from 'plyr';
import Hls from 'hls.js';
import { Media } from 'src/interfaces/player';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoPlayerComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() media?: Media;
  @Input() playlist: Media[] = [];
  @ViewChild('videoRef') videoRef!: ElementRef<HTMLVideoElement>;
  player!: Plyr;
  private hls!: Hls;
  private currentIndex = 0;
  private qualityLevels: Array<{ label: string; value: number }> = [];
  message = '';
  private playerInitialized = false;
  private previousMediaSrc?: string;

  constructor(
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private readonly platformId: Object
  ) {}

  ngOnInit() {
    if (!this.media) {
      console.error('No media input provided');
    }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId) && this.media && !this.playerInitialized) {
      this.initializePlayer();
      this.playerInitialized = true;
      this.previousMediaSrc = this.media.src;

      this.videoRef.nativeElement.addEventListener('click', this.togglePlayPause.bind(this));
      document.addEventListener('fullscreenchange', this.handleFullscreenChange.bind(this));
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.cleanupPlayer();
      document.removeEventListener('fullscreenchange', this.handleFullscreenChange.bind(this));
      this.videoRef.nativeElement.pause();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (isPlatformBrowser(this.platformId) && changes['media'] && !changes['media'].firstChange) {
      if (this.media && this.media.src !== this.previousMediaSrc) {
        this.initializePlayer();
        this.previousMediaSrc = this.media.src;
      }
    }
  }

  private initializePlayer() {
    const videoElement = this.videoRef?.nativeElement;
    if (!videoElement || !this.media?.src) {
      console.error('Invalid video element or media source');
      this.message = 'Invalid media source. Please try again later.';
      this.cdr.detectChanges();
      return;
    }

    videoElement.onerror = () => {
      console.error('Failed to load video:', this.media?.src);
      this.message = 'Unable to load video. Please try again later.';
      this.cdr.detectChanges();
    };

    if (this.media.src.endsWith('.m3u8')) {
      this.setupHls(videoElement);
    } else if (videoElement.canPlayType('video/mp4')) {
      this.setupNonHls(videoElement);
    } else {
      console.error('Unsupported video format:', this.media.src);
      this.message = 'This video format is not supported by your browser.';
      this.cdr.detectChanges();
      return;
    }

    this.setupPlyr(videoElement);
    this.cdr.markForCheck();
  }

  private setupPlyr(videoElement: HTMLVideoElement) {
    const options: Plyr.Options = {
      captions: { active: true, update: true, language: 'auto' },
      autoplay: true,
      controls: [
        'next', 'prev', 'play-large', 'restart', 'rewind', 'play', 'fast-forward', 'progress',
        'current-time', 'duration', 'mute', 'volume', 'captions', 'settings', 'fullscreen'
      ],
      settings: ['captions', 'quality', 'speed'],
      quality: {
        default: 720,
        options: [360, 480, 720, 1080],
        forced: true,
        onChange: (quality: number) => this.changeQuality(quality)
      },
      speed: {
        selected: 1,
        options: [0.5, 0.75, 1, 1.25, 1.5, 2]
      }
    };

    this.player = new Plyr(videoElement, options);

    this.player.on('ready', () => this.addCustomControls());
    this.player.on('ended', () => this.playNext());
  }

  private setupHls(videoElement: HTMLVideoElement) {
    if (Hls.isSupported()) {
      this.hls = new Hls({ capLevelToPlayerSize: true, autoStartLoad: true, enableWorker: true });
      this.hls.loadSource(this.media!.src);
      this.hls.attachMedia(videoElement);

      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        this.setupQualityLevels();
        this.playVideo();
      });

      this.hls.on(Hls.Events.ERROR, (_, data) => {
        if (data.fatal) {
          if (data.type === Hls.ErrorTypes.NETWORK_ERROR) this.hls.startLoad();
          else if (data.type === Hls.ErrorTypes.MEDIA_ERROR) this.hls.recoverMediaError();
          else this.hls.destroy();
        }
      });
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      videoElement.src = this.media!.src;
      videoElement.addEventListener('loadedmetadata', () => this.playVideo());
    } else {
      console.error('HLS is not supported in this browser');
    }
  }

  private setupQualityLevels() {
    if (this.hls) {
      this.qualityLevels = this.hls.levels
        .filter(level => level.height > 0)
        .map((level, index) => ({ label: `${level.height}p`, value: index }));

      if (this.qualityLevels.length > 0) {
        this.qualityLevels.unshift({ label: 'Auto', value: -1 });
        (this.player as any).quality = {
          default: -1,
          options: this.qualityLevels.map(level => level.value),
          forced: true,
          onChange: (quality: number) => this.changeQuality(quality)
        };
      } else {
        this.message = 'This video has only one quality available.';
        this.cdr.detectChanges();
        this.clearMessageAfterTimeout();
      }
    }
  }

  private changeQuality(newQuality: number) {
    if (this.hls) {
      const currentTime = this.videoRef.nativeElement.currentTime;
      this.hls.currentLevel = newQuality;
      this.hls.on(Hls.Events.LEVEL_SWITCHED, () => {
        this.videoRef.nativeElement.currentTime = currentTime;
        this.playVideo();
      });
    }
  }

  private setupNonHls(videoElement: HTMLVideoElement) {
    videoElement.src = this.media!.src;
    videoElement.addEventListener('loadedmetadata', () => this.playVideo());
  }

  private playVideo() {
    const playPromise = this.videoRef.nativeElement.play();
    if (playPromise) playPromise.catch(error => console.error('Error starting playback:', error));
  }

  private cleanupPlayer() {
    this.hls?.destroy();
    this.player?.destroy();
  }

  private togglePlayPause() {
    const videoElement = this.videoRef.nativeElement;
    videoElement.paused ? videoElement.play() : videoElement.pause();
  }

  private handleFullscreenChange() {
    if (document.fullscreenElement) this.lockOrientation('landscape');
    else this.unlockOrientation();
  }

  private lockOrientation(orientation: 'landscape' | 'portrait') {
    if (screen.orientation?.lock) {
      screen.orientation.lock(orientation).catch(err => console.error('Orientation lock failed:', err));
    }
  }

  private unlockOrientation() {
    screen.orientation?.unlock?.();
  }

  private addCustomControls() {
    const controls = this.player.elements.controls;
    if (controls) {
      const nextButton = this.createControlButton('Next', 'plyr__control--next', () => this.playNext());
      const prevButton = this.createControlButton('Prev', 'plyr__control--prev', () => this.playPrevious());
      controls.appendChild(prevButton);
      controls.appendChild(nextButton);
    }
  }

  private createControlButton(label: string, className: string, onClick: () => void): HTMLElement {
    const button = document.createElement('button');
    button.className = `plyr__controls__item ${className}`;
    button.innerHTML = label;
    button.addEventListener('click', onClick);
    return button;
  }

  private clearMessageAfterTimeout() {
    setTimeout(() => {
      this.message = '';
      this.cdr.detectChanges();
    }, 5000);
  }

  playNext() {
    this.currentIndex = (this.currentIndex + 1) % this.playlist.length;
    this.media = this.playlist[this.currentIndex];
    this.initializePlayer();
  }

  playPrevious() {
    this.currentIndex = (this.currentIndex - 1 + this.playlist.length) % this.playlist.length;
    this.media = this.playlist[this.currentIndex];
    this.initializePlayer();
  }
}