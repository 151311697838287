import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { ContentState } from '../store/state/content.state';
import {
  selectContent,
  selectContentLoading,
  selectContentError,
} from '../store/selectors/content.selectors';
import { selectContent as selectContentAction } from '../store/actions/content.actions';
import { Media } from 'src/interfaces/player';
import { ContentItem } from 'src/interfaces/content';

@Component({
  selector: 'app-movie-detail',
  templateUrl: './movie-detail.component.html',
  styleUrls: ['./movie-detail.component.scss']
})
export class MovieDetailComponent implements OnInit, OnDestroy {
  movie$!: Observable<Media | null>;
  loading$!: Observable<boolean>;
  error$!: Observable<string | null>;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store<ContentState>
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(
      switchMap(params => {
        const contentId = params['id'];
        const contentType = this.route.snapshot.url[0].path; // Get content type from URL
        return of({ contentId, contentType });
      }),
      take(1)
    ).subscribe(({ contentId, contentType }) => {
      if (contentId && contentType) {
        this.store.dispatch(selectContentAction({ contentId, contentType }));
        this.movie$ = this.store.select(selectContent).pipe(
          map((selectedContent: ContentItem | null) => {
            return selectedContent ? this.mapToMedia(selectedContent) : null;
          }),
          catchError((error: any) => {
            if (error.status === 401) {
              this.router.navigate(['/login']);
            }
            return throwError(() => error);
          }),
        );
        this.loading$ = this.store.select(selectContentLoading);
        this.error$ = this.store.select(selectContentError);
      }
    });
  }

  ngOnDestroy(): void {
    this.movie$ = of(null); // Stop the movie when the component is destroyed
  }

  private mapToMedia(data: ContentItem): Media {
    return {
      id: data.id,
      title: data.title,
      description: data.description,
      contentId: data.contentId,
      contentTheme: data.contentTheme,
      src: data.signedUrl ?? data.movieUrl ?? '',
      type: 'video/mp4',
      thumbnail: data.image,
      label: data.label,
      resolution: data.resolution,
      ratio: data.ratio,
      autoplay: true,
      image: data.imageSignedUrl ?? data.image,
      movieMetadata: data.movieMetadata ? {
        director: data.movieMetadata.director,
        releaseDate: data.movieMetadata.releaseDate,
        duration: data.movieMetadata.duration,
        rating: data.movieMetadata.rating,
        genre: data.movieMetadata.genre
      } : undefined
    };
  }

  goBack() {
    this.router.navigate(['/content']);
  }

  generateStars(rating: number): boolean[] {
    return Array(10).fill(false).map((_, i) => i < rating);
  }

  playTrailer(): void {
    // Play the trailer
  }
}