<div class="profile-container">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple" *ngIf="isLoading"></ngx-spinner>
  <app-light-logo></app-light-logo>

  <h1>Profile</h1>

  <div class="profile-switcher">
    <mat-slide-toggle
      [checked]="isAdultProfile()"
      (change)="switchProfile($event)"
      color="primary"
    >
      {{ isAdultProfile() ? 'Adult Profile' : 'Child Profile' }}
    </mat-slide-toggle>
  </div>

  <div class="content" *ngIf="!isLoading">
    <mat-card class="profile-card">
      <img mat-card-image src="../../assets/default-profile-pic.png" alt="Profile" />
      <mat-card-content>
        <div class="pro-item">
          <span>Email: <b>{{ userInfo?.email }}</b></span>
          <span>Username: <b>{{ userInfo?.username }}</b></span>
          <span>Phone: <b>{{ userInfo?.phoneNumber ?? 'Not Available' }}</b></span>
          <a routerLink="/reset-password">Change password</a>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="subscription-card" *ngIf="hasSubscriptions; else noSubscriptions">
      <mat-card-header>
        <mat-card-title>My Subscription</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="sub-item">
          <span>Type</span>
          <mat-chip [ngStyle]="{ 
            'background-color': plan !== 'N/A' ? 'gray' : 'lightgray', 
            color: 'white' 
            }">{{ plan }}
          </mat-chip>
        </div>
        <mat-divider></mat-divider>
        <div class="sub-item">
          <span>Plan Status</span>
          <mat-chip [ngStyle]="{ 
            'background-color': subscriptionInfo?.activeStatus ? '#7e57c2' : 'lightgray', 
            color: 'white' 
            }">{{ subscriptionInfo?.activeStatus ? "Active" : "Inactive" }}
          </mat-chip>
        </div>
        <div class="sub-item">
          <span>Subscription Status</span>
          <mat-chip [ngStyle]="{ 
            'background-color': subscriptionIsActiveStatus ? '#7e57c2' : 'lightgray', color: 'white' 
            }">{{ subscriptionIsActiveStatus ? "Active" : "Cancelled" }}
          </mat-chip>
        </div>
        <mat-divider></mat-divider>
        <div class="sub-item">
          <span>Time Left</span>
          <span>{{ daysToExpiry }}</span>
        </div>
        <button mat-raised-button (click)="openSubscriptionModal()">
          Show Subscription Plans
        </button>
      </mat-card-content>
    </mat-card>

    <ng-template #noSubscriptions>
      <mat-card class="subscription-card">
        <mat-card-header>
          <mat-card-title>No Subscriptions</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>You have no subscriptions at the moment.</p>
        </mat-card-content>
      </mat-card>
    </ng-template>

    <mat-card class="actions-card">
      <mat-card-content>
        <div class="pro-item">
          <button
            mat-raised-button
            color="accent"
            class="btn"
            (click)="getSubscriptionByIdAndNavigate(subscriptionInfo?.id)"
            [disabled]="!hasSubscriptions"
          >
            View Subscription Details
          </button>
          <button
            mat-raised-button
            color="primary"
            class="sub-btn"
            [disabled]="!subscriptionIsActiveStatus"
            (click)="cancelSubscription()"
          >
            Cancel Subscription
          </button>
          <button
            mat-raised-button
            color="accent"
            class="act-btn"
            [disabled]="!isSubscriptionCancelledAndNotExpired() || !hasSubscriptions"
            (click)="activateSubscription()"
          >
            Activate Subscription
          </button>
        </div>
        <div class="pro-item">
          <input
            matInput
            placeholder="Type DELETE to start Account Deletion"
            [(ngModel)]="confirmDeleteText"
          />
          <button
            mat-raised-button
            color="warn"
            class="del-btn"
            [disabled]="confirmDeleteText !== 'DELETE'"
            (click)="deleteAccount()"
          >
            Delete Account
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="back-button-container" *ngIf="!isLoading">
    <button mat-icon-button color="primary" class="back-btn" (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
      <span class="back-text">Back</span>
    </button>
  </div>
</div>