import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.scss']
})
export class SubscriptionDetailsComponent implements OnInit {
  subscription: any;
  isLoading = true;
  error: string | null = null;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly subscriptionService: SubscriptionService,
    private readonly spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    const subscriptionId = this.route.snapshot.paramMap.get('id');
    if (subscriptionId) {
      this.subscriptionService.getSubscription(subscriptionId).subscribe({
        next: (response) => {
          this.subscription = response.data;
          this.isLoading = false;
          this.spinner.hide();
        },
        error: (err) => {
          this.error = err.message || 'Failed to load subscription details.';
          this.isLoading = false;
          this.spinner.hide();
        }
      });
    } else {
      this.error = 'Subscription ID not found in route parameters.';
      this.isLoading = false;
      this.spinner.hide();
    }
  }

  goBack(): void {
    this.router.navigate(['/profile']);
  }

  cancelSubscription(): void {
    if (confirm('Are you sure you want to cancel this subscription?')) {
      this.spinner.show();
      this.subscriptionService.cancelSubscription(this.subscription.id).subscribe({
        next: () => {
          alert('Subscription canceled successfully.');
          this.router.navigate(['/content']);
        },
        error: (err) => {
          this.error = err.message || 'Failed to cancel subscription.';
          this.spinner.hide();
        }
      });
    }
  }

  getActiveStatus(): string {
    return this.subscription.activeStatus ? 'Active' : 'Inactive';
  }
}