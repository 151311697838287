<mat-dialog-content class="mat-dialog-container">
  <div class="modal-header">
    <h1 mat-dialog-title>{{ media.title }}</h1>
  </div>
  
  <div class="modal-body">
    <app-video-player [media]="media" [playlist]="data.playlist"></app-video-player>
  </div>
  
  <div class="modal-footer">
    <button mat-button (click)="onNoClick()">Close</button>
  </div>
</mat-dialog-content>