import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserModel } from '../models/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SigninComponent implements OnInit {
  constructor(
    private readonly auth: AuthenticationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private readonly location: Location
  ) {}

  isLoading: boolean = false;

  signInForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ])
  });

  async ngOnInit() {
    this.checkAuthState();

    // Check for Google auth error
    this.route.queryParams.subscribe(params => {
      if (params['error'] === 'auth_failed') {
        this.showErrorMessage('Google authentication failed. Please try again.');
      }
    });
  }

  checkAuthState = async () => {
    let canAuthenticateResult = await this.auth.canActivate();

    if (canAuthenticateResult === true) {
      this.router.navigate(['/content'], { replaceUrl: true });
      this.location.replaceState('/content'); // Replace state in history stack
    }
  };

  user = new UserModel('', '');
  emailControl = this.signInForm.get('email');
  passwordControl = this.signInForm.get('password');

  // Validate email
  getEmailErrorMessage() {
    if (
      this.emailControl?.hasError('required') ||
      this.emailControl?.hasError('email') ||
      this.emailControl?.hasError('pattern')
    ) {
      return 'Please enter a valid email';
    } else {
      return '';
    }
  }

  // Validate password
  getPasswordErrorMessage() {
    if (this.passwordControl?.hasError('required')) {
      return 'Please enter a password';
    }

    if (this.passwordControl?.value.length < 8) {
      return 'Password too short';
    }
    return '';
  }

  signIn() {
    if (this.signInForm.valid) {
      this.isLoading = true;

      const observer = {
        next: (response: any) => {
          if (response.success) {
            if (response.data.user.emailVerified) {
              if (response.data.user.isAdmin) {
                this.showSuccessMessage('Sign in successful!');
                setTimeout(() => {
                  this.router.navigate(['/admin'], { replaceUrl: true });
                  this.location.replaceState('/admin'); // Replace state in history stack
                }, 2000);
              } else {
                this.showSuccessMessage('Sign in successful!');
                setTimeout(() => {
                  this.router.navigate(['/content'], { replaceUrl: true });
                  this.location.replaceState('/content'); // Replace state in history stack
                }, 2000);
              }
            } else {
              this.showErrorMessage('Please verify your email');
            }
          } else {
            this.handleErrorResponse(response);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.handleErrorResponse(error);
          this.isLoading = false;
        }
      };

      this.auth.signIn(
        this.signInForm.value.email,
        this.signInForm.value.password
      ).subscribe(observer);
    } else {
      this.showErrorMessage('Please fill out the form correctly.');
    }
  }

  handleErrorResponse(error: any) {
    const errorMessage = error.error?.message || 'Oops! Something went wrong.';
    switch (errorMessage) {
      case 'User not found':
        this.showErrorMessage('Account not found.');
        break;
      case 'Email not verified':
        this.showErrorMessage('Please verify your email');
        break;
      case 'Invalid password':
        this.showErrorMessage('Incorrect password');
        break;
      case 'Account marked for deletion':
        this.showErrorMessage('Account marked for deletion');
        break;
      default:
        this.showErrorMessage(errorMessage);
        break;
    }
  }

  showErrorMessage(message: string) {
    this.snackBar.open(`❌ ${message}`, 'Close', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['snackbar--error']
    });
  }

  showSuccessMessage(message: string) {
    this.snackBar.open(`✅ ${message}`, 'Close', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['snackbar--success']
    });
  }

  // Password visibility toggle
  hide = true;
}