<section class="welcome-message">
  <div class="left-section"></div>
  <div class="center-section">
    <h1>Entertainment for the Whole Family</h1>
    <p>Explore movies, shows, and podcasts that bring joy to every member of your family</p>
    <a routerLink="/signup" class="centered-btn">
      <button mat-stroked-button class="start-free-trial-btn">{{ startFreeTrialBtnLabel }}</button>
    </a>
  </div>
  <div class="right-section"></div>
</section>