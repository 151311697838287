import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private readonly subscriptionUrl: string = `${environment.apiBaseUrl}/api/subscriptions/`;

  constructor(private readonly httpClient: HttpClient) {}

  private getAuthHeaders(): HttpHeaders {
    const accessToken = sessionStorage.getItem('2lietkapas');
    let headers = new HttpHeaders();
    if (accessToken) {
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  }

  // Enhanced error handling
  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      // Client-side/network error
      errorMessage = `A client-side error occurred: ${error.error.message}`;
    } else {
      // Server-side error
      switch (error.status) {
        case 400:
          errorMessage = 'Bad request. Please check the data and try again.';
          break;
        case 404:
          errorMessage = 'Resource not found. Please check the URL or try again later.';
          break;
        case 401:
          errorMessage = 'Unauthorized access. Please login and try again.';
          break;
        case 403:
          errorMessage = 'Access forbidden. Please check your permissions.';
          break;
        case 500:
          errorMessage = 'Server error. Please try again later.';
          break;
        default:
          errorMessage = 'An unexpected error occurred. Please try again later.';
      }
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }

  // Create a new subscription
  createSubscription(subscriptionData: any): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.post(this.subscriptionUrl, subscriptionData, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Create a free subscription
  createFreeSubscription(userId: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.post(`${this.subscriptionUrl}free/${userId}`, {}, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Activate a subscription
  activateSubscription(subscriptionId: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.patch(`${this.subscriptionUrl}activate/${subscriptionId}`, {}, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Cancel a subscription
  cancelSubscription(subscriptionId: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.patch(`${this.subscriptionUrl}cancel/${subscriptionId}`, {}, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Get a specific subscription
  getSubscription(subscriptionId: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.get(`${this.subscriptionUrl}${subscriptionId}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Get all subscriptions for a user
  getSubscriptions(userId: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.get(`${this.subscriptionUrl}user/${userId}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Check if user has an active subscription
  hasActiveSubscription(userId: string): Observable<boolean> {
    return this.getSubscriptions(userId).pipe(
      map((response: any) => {
        const subscriptions = response.data;
        return Array.isArray(subscriptions) && subscriptions.some((sub: any) => sub.activeStatus);
      }),
      catchError((error: any) => {
        if (error.status === 404 || error.message.includes('Resource not found')) {
          return of(false);
        } else {
          return this.handleError(error);
        }
      })
    );
  }

  // Optional: Loading indicator management
  setLoading(loading: boolean): void {
    // Implement logic to show or hide a loading indicator
    // For example, using a BehaviorSubject to notify components
  }
}