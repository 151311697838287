<div class="profile-container">
  <button class="back-button" (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>
    <span class="back-text">Back</span>
  </button>
  <div class="logo-container">
    <app-light-logo></app-light-logo>
  </div>
  <div class="profile-form">
    <h2>Update Profile</h2>
    <form [formGroup]="profileForm" (ngSubmit)="updateProfile()">
      <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
      <div class="form-row">
        <div class="form-group">
          <label for="email">Email:</label>
          <input id="email" formControlName="email" type="email" class="form-control" [class.is-invalid]="profileForm.get('email')?.invalid && profileForm.get('email')?.touched">
          <div *ngIf="profileForm.get('email')?.invalid && profileForm.get('email')?.touched" class="invalid-feedback">
            Please enter a valid email.
          </div>
        </div>
        <div class="form-group">
          <label for="username">Username:</label>
          <input id="username" formControlName="username" type="text" class="form-control" [class.is-invalid]="profileForm.get('username')?.invalid && profileForm.get('username')?.touched">
          <div *ngIf="profileForm.get('username')?.invalid && profileForm.get('username')?.touched" class="invalid-feedback">
            Username is required.
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="phoneNumber">Phone Number:</label>
          <input id="phoneNumber" formControlName="phoneNumber" type="text" class="form-control">
        </div>
        <div class="form-group">
          <label for="currency">Currency:</label>
          <input id="currency" formControlName="currency" type="text" class="form-control">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="profileType">Profile Type:</label>
          <select id="profileType" formControlName="profileType" class="form-control">
            <option value="adult">Adult</option>
            <option value="child">Child</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="oldPassword">Old Password:</label>
          <div class="password-container">
            <input id="oldPassword" formControlName="oldPassword" [type]="showOldPassword ? 'text' : 'password'" class="form-control" [class.is-invalid]="profileForm.get('oldPassword')?.invalid && profileForm.get('oldPassword')?.touched">
            <mat-icon class="toggle-password" (click)="togglePasswordVisibility('old')" (keydown)="togglePasswordVisibility('old')">{{ showOldPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </div>
          <div *ngIf="profileForm.get('oldPassword')?.invalid && profileForm.get('oldPassword')?.touched" class="invalid-feedback">
            Old password is required.
          </div>
        </div>
        <div class="form-group">
          <label for="newPassword">New Password:</label>
          <div class="password-container">
            <input id="newPassword" formControlName="newPassword" [type]="showNewPassword ? 'text' : 'password'" class="form-control" [class.is-invalid]="profileForm.get('newPassword')?.invalid && profileForm.get('newPassword')?.touched">
            <mat-icon class="toggle-password" (click)="togglePasswordVisibility('new')" (keydown)="togglePasswordVisibility('new')">{{ showNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </div>
          <div *ngIf="profileForm.get('newPassword')?.invalid && profileForm.get('newPassword')?.touched" class="invalid-feedback">
            New password must be at least 6 characters long.
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="confirmNewPassword">Confirm New Password:</label>
          <div class="password-container">
            <input id="confirmNewPassword" formControlName="confirmNewPassword" [type]="showConfirmNewPassword ? 'text' : 'password'" class="form-control" [class.is-invalid]="profileForm.get('confirmNewPassword')?.invalid && profileForm.get('confirmNewPassword')?.touched">
            <mat-icon class="toggle-password" (click)="togglePasswordVisibility('confirm')" (keydown)="togglePasswordVisibility('confirm')">{{ showConfirmNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </div>
          <div *ngIf="profileForm.get('confirmNewPassword')?.invalid && profileForm.get('confirmNewPassword')?.touched" class="invalid-feedback">
            Passwords must match.
          </div>
        </div>
      </div>
      <div class="button-container">
        <button type="submit" class="btn" [disabled]="loading">Update Profile</button>
      </div>
    </form>
  </div>
</div>