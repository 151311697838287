<div class="otp-verification">
  <div class="back-arrow" (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>
    <span>Back</span>
  </div>
  <div class="otp-container">
    <h2>Verify OTP</h2>
    <form [formGroup]="otpForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="email">Enter Email</label>
        <input id="email" formControlName="email" type="email" class="form-control" placeholder="Enter your email" />
        <div *ngIf="otpForm.get('email')?.invalid && otpForm.get('email')?.touched" class="error">
          <div *ngIf="otpForm.get('email')?.errors?.['required']">Email is required.</div>
          <div *ngIf="otpForm.get('email')?.errors?.['email']">Valid email is required.</div>
        </div>
      </div>
      <div class="form-group">
        <label for="otp">Enter OTP</label>
        <input id="otp" formControlName="otp" type="text" class="form-control" placeholder="Enter your OTP" />
        <div *ngIf="otpForm.get('otp')?.invalid && otpForm.get('otp')?.touched" class="error">
          OTP is required and must be 6 digits.
        </div>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="otpForm.invalid || loading">Verify</button>
    </form>
    <div *ngIf="loading" class="loading-spinner">
      <output class="spinner-border">
        <span class="sr-only">Loading...</span>
      </output>
    </div>
  </div>
</div>