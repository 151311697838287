<div class="container-fluid">
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    *ngIf="(isLoading$ | async) && !(isContentLoaded$ | async)"
  ></ngx-spinner>

  <app-top-content [contentData]="randomPost" [isPaid]="isPaid"></app-top-content>

  <div class="content" *ngIf="isPaid && !errorMessage; else noSubscription">
    <!-- Movies section -->
    <div class="slider-section" *ngIf="currentContentType === 'movies' || currentContentType === ''">
      <h3>Movies</h3>
      <ng-container *ngIf="(moviesData$ | async) as moviesData">
        <ng-container *ngIf="moviesData.length > 0; else noMovies">
          <app-slider [contentData]="moviesData" [isFiltering]="currentContentType === 'movies'" (itemClick)="onItemClick($event)"></app-slider>
        </ng-container>
      </ng-container>
      <ng-template #noMovies>
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        ></ngx-spinner>
        <p>🎬 We're working hard to bring you the best movies soon! Stay tuned. 📡</p>
      </ng-template>
    </div>

    <!-- Shows section -->
    <div class="slider-section" *ngIf="currentContentType === 'shows' || currentContentType === ''">
      <h3>Shows</h3>
      <ng-container *ngIf="(shows$ | async) as showsData">
        <ng-container *ngIf="showsData.length > 0; else noShows">
          <app-slider [contentData]="showsData" [isFiltering]="currentContentType === 'shows'" (itemClick)="onItemClick($event)"></app-slider>
        </ng-container>
      </ng-container>
      <ng-template #noShows>
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        ></ngx-spinner>
        <p>📺 Keep an eye out for amazing shows! New episodes are on the horizon. 🚀</p>
      </ng-template>
    </div>

    <!-- Podcasts section -->
    <div class="slider-section" *ngIf="currentContentType === 'podcasts' || currentContentType === ''">
      <h3>Podcasts</h3>
      <ng-container *ngIf="(podcasts$ | async) as podcastsData">
        <ng-container *ngIf="podcastsData.length > 0; else noPodcasts">
          <app-slider [contentData]="podcastsData" [isFiltering]="currentContentType === 'podcasts'" (itemClick)="onItemClick($event)"></app-slider>
        </ng-container>
      </ng-container>
      <ng-template #noPodcasts>
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        ></ngx-spinner>
        <p>🎧 Discover exciting podcasts coming your way! Subscribe now to get notified. 🔔</p>
      </ng-template>
    </div>

    <!-- Latest Audio Feed section -->
    <div class="slider-section" *ngIf="currentContentType === 'rssFeeds' || currentContentType === ''">
      <h3>Latest Audio Feed</h3>
      <ng-container *ngIf="(rssFeeds$ | async) as rssFeedsData">
        <ng-container *ngIf="rssFeedsData.length > 0; else noRssFeeds">
          <app-slider [contentData]="rssFeedsData" [isFiltering]="currentContentType === 'rssFeeds'" (itemClick)="onItemClick($event)"></app-slider>
        </ng-container>
      </ng-container>
      <ng-template #noRssFeeds>
        <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        ></ngx-spinner>
        <p>🎙️ Stay tuned for the latest audio feeds! New content is on the way. 📡</p>
      </ng-template>
    </div>
  </div>

  <ng-template #noSubscription>
    <div class="subscription-modal">
      <p>Your subscription is required to access premium content. Please subscribe to continue.</p>
      <button mat-button (click)="openSubscriptionModal()">Subscribe Now</button>
    </div>
  </ng-template>
</div>