import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { PageStateService } from 'src/app/services/pagestate.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  signInBtnLabel: string = 'Login';
  signUpBtnLabel: string = 'Sign up';
  showScrollToTop: boolean = false;
  isFaqPage: boolean = false;
  faqs: { question: string, answer: string, showAnswer?: boolean, category?: string }[] = [
    // General FAQs
    {
      question: 'What is NWPLUS TV?',
      answer: 'NWPLUS TV is a streaming service that offers a wide variety of movies, shows, and podcasts for the whole family.',
      category: 'General'
    },
    {
      question: 'How do I contact customer support?',
      answer: 'You can contact customer support by visiting the "Contact Us" page and filling out the contact form.',
      category: 'General'
    },
    {
      question: 'Are there parental controls available?',
      answer: 'Yes, NWPLUS TV offers parental controls to help you manage what your children can watch.',
      category: 'General'
    },
    {
      question: 'Finding TV shows, movies, and podcasts',
      answer: 'Use the search box on the content page to quickly discover movies and shows you\'ll love!',
      category: 'General'
    },
    {
      question: 'How do I make a complaint?',
      answer: `To make a complaint, you can use the following methods:
      - By Post: NW+ TV (NW PLUS TV), P.O, Box 21933-00505, Ngong Road, Nairobi, Kenya
      - By Email: complaints@nwplus.biz
      - Through our website: www.nwplus.biz
      - By Telephone: 020 4403813

      If you are physically challenged or lack language/writing skills, our team will assist you in recording and submitting your complaint. Please call our Customer Service line for assistance.`,
      category: 'General'
    },
    {
      question: 'What information should I provide when submitting a complaint?',
      answer: `When submitting your complaint, please provide the following information:
      - Your full name and address
      - The date and time of the programme complained about
      - The substance of your complaint
      - Evidence of the complaint (e.g., unauthorized charges, billing problems, technical issues, material broadcast)

      You may submit your complaint in either English or Kiswahili. Please retain a copy of all correspondence between you and NW+ TV for your reference and records.`,
      category: 'General'
    },
    {
      question: 'How will my complaint be investigated?',
      answer: `Once we receive a complaint, a member of our Complaints Handling Team will classify the complaint into one of the following categories:
      - Programming: Complaints about content, scheduling, or lack of certain programs
      - Technical issues: Complaints on reception, picture quality, or sound
      - Customer Service: Issues related to billing, website navigation, or unhelpful staff

      The complaint will be investigated based on the information provided by you. Where additional information is required, you will be requested to provide such additional information within 3 days of receiving a request.`,
      category: 'General'
    },
    {
      question: 'How will my complaint be resolved?',
      answer: `We will investigate your complaint, considering our platform's content guidelines and policies, and resolve the complaint within a reasonable timeframe, typically within 21 days. If more time is required, we will inform you accordingly. Upon resolution, you will be notified of the outcome. If no action is proposed, an explanation will be provided.`,
      category: 'General'
    },
    {
      question: 'What if I am not satisfied with the resolution of my complaint?',
      answer: `If you are not satisfied with the resolution of your complaint, please forward your complaint to escalations@nwplus.biz stating the reasons for your dissatisfaction. A Senior member of our Complaints Handling Team will review the complaint and the proposed resolution and respond substantively to you in writing within 30 days.`,
      category: 'General'
    },
    {
      question: 'How long will my complaint be retained?',
      answer: `All complaints will be treated confidentially and retained for a minimum period of one (1) year after the resolution of the complaint, depending on its nature.`,
      category: 'General'
    },
    {
      question: 'Which complaints will not be addressed?',
      answer: `NW+ TV will not respond to or investigate complaints that are:
      - Anonymous
      - Do not contain all the requested information
      - Frivolous, vexatious, malicious, or generally an abuse of the Complaints Handling Process`,
      category: 'General'
    },
    {
      question: 'How can I provide feedback on the Complaints Handling Process?',
      answer: `NW+ TV encourages customers to provide feedback on the Complaints Handling Process to assist us in continuously improving service quality. We will use customer feedback to refine the process and improve overall user satisfaction.`,
      category: 'General'
    },

    // Account FAQs
    {
      question: 'How do I sign up?',
      answer: 'You can sign up by clicking the "Sign up" button on the homepage and filling out the registration form.',
      category: 'Account'
    },
    {
      question: 'How do I reset my password?',
      answer: 'To reset your password, click on the "Forgot Password" link on the sign-in page and follow the instructions.',
      category: 'Account'
    },
    {
      question: 'What is the NWPLUS TV Free Plan?',
      answer: 'If you are new to NW+ TV, you can enjoy a free 24-hour trial to explore all the features and content our platform offers. Additionally, keep an eye out for our promotional campaigns where we occasionally offer free plans. Don’t miss out on these opportunities!',
      category: 'Account'
    },
    {
      question: 'How do I cancel my subscription?',
      answer: 'You can cancel your subscription at any time by going to your profile page and selecting "Cancel Subscription".',
      category: 'Account'
    },
    {
      question: 'Can I share my account with others?',
      answer: 'This feature is in the works and will be ready for you soon—stay tuned!',
      category: 'Account'
    },
    {
      question: 'How do I change my account settings?',
      answer: 'You can change your account settings by logging into your account and navigating to the settings page.',
      category: 'Account'
    },
    {
      question: 'Signing in',
      answer: 'Once you\'ve opened the NWPLUS TV website, select Sign In to access your account and begin watching TV shows, movies, and podcasts. You can sign in on any NWPLUS TV-compatible device.',
      category: 'Account'
    },
    {
      question: 'Creating profiles',
      answer: 'The main account holder must be at least 18 years old, and the account is set to adult mode by default. If a child wants to use the platform, simply switch to a child profile in your profile settings.',
      category: 'Account'
    },
    {
      question: 'Managing your account',
      answer: 'You can update your account information at any time, and change your password, or subscription plan by navigating to the profile page.',
      category: 'Account'
    },

    // Technical FAQs
    {
      question: 'What devices can I use to watch NWPLUS TV?',
      answer: 'You can watch NWPLUS TV on mobile/smart devices, and web browsers.',
      category: 'Technical'
    },
    {
      question: 'Can I download content to watch offline?',
      answer: 'This feature is in the works and will be ready for you soon—stay tuned!',
      category: 'Technical'
    },
    {
      question: 'Watching on the go',
      answer: 'This feature is in the works and will be ready for you soon—stay tuned!',
      category: 'Technical'
    },

    // Billing FAQs
    {
      question: 'NWPLUS Subscription Plans and Pricing',
      answer: 'Choose from three flexible plans designed to suit your entertainment needs: 24-hour, weekly, or monthly. When your subscription expires, simply renew by selecting your preferred plan and making payment via mobile money or a debit/credit card.',
      category: 'Billing'
    },
    {
      question: 'How do I update my payment information?',
      answer: 'To update your payment information, you need to cancel your current subscription if you have an active one. Then, go to the content page and click on the subscribe button. A modal will appear where you can select a plan. Click the confirm button on the modal, and you will be redirected to Flutterwave to complete the payment process.',
      category: 'Billing'
    }
  ];
  filteredFaqs = this.faqs;
  categories = ['All', 'General', 'Account', 'Technical', 'Billing'];

  constructor(
    private readonly router: Router,
    private readonly pageStateService: PageStateService
  ) { }

  ngOnInit(): void {
    this.isFaqPage = this.router.url.includes('/faq');
    this.pageStateService.setIsFaqPage(this.isFaqPage);
    console.log('FAQ page loaded', this.isFaqPage);
  }

  toggleAnswer(selectedFaq: { question: string, answer: string, showAnswer?: boolean }): void {
    this.faqs.forEach(faq => {
      if (faq !== selectedFaq) {
        faq.showAnswer = false;
      }
    });
    selectedFaq.showAnswer = !selectedFaq.showAnswer;
  }

  goBack(): void {
    this.router.navigate(['/']);
  }

  filterFaqs(event: Event): void {
    const query = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredFaqs = this.faqs.filter(faq => faq.question.toLowerCase().includes(query));
  }

  filterByCategory(category: string): void {
    if (category === 'All') {
      this.filteredFaqs = this.faqs;
    } else {
      this.filteredFaqs = this.faqs.filter(faq => faq.category === category);
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    this.showScrollToTop = window.pageYOffset > 300;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}